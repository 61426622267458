import React, { useState, useEffect, useRef } from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";

import { motion } from "framer-motion";
import { ImageGallery } from "react-image-grid-gallery";
import VideoPlayer from "./components/VideoComponent";

import image1 from "../assets/images/magicalmoments/image1.png";
import image2 from "../assets/images/magicalmoments/image2.png";
import image3 from "../assets/images/magicalmoments/image3.png";
import image4 from "../assets/images/magicalmoments/image4.png";
import image5 from "../assets/images/magicalmoments/image5.png";
import image6 from "../assets/images/magicalmoments/image6.png";
import image7 from "../assets/images/magicalmoments/image7.png";
import image8 from "../assets/images/magicalmoments/image8.png";
import image9 from "../assets/images/magicalmoments/image9.png";
import image10 from "../assets/images/magicalmoments/image10.png";
import image11 from "../assets/images/magicalmoments/image11.png";
import image12 from "../assets/images/magicalmoments/image12.png";
import image13 from "../assets/images/magicalmoments/image13.png";
import image14 from "../assets/images/magicalmoments/image14.png";
import image15 from "../assets/images/magicalmoments/image15.png";
import image16 from "../assets/images/magicalmoments/image16.png";
import image17 from "../assets/images/magicalmoments/image17.png";
import image18 from "../assets/images/magicalmoments/image18.png";
import image19 from "../assets/images/magicalmoments/image19.png";
import image20 from "../assets/images/magicalmoments/image20.png";
import image21 from "../assets/images/magicalmoments/image21.png";
import image22 from "../assets/images/magicalmoments/image22.png";
import image23 from "../assets/images/magicalmoments/image23.png";
import image24 from "../assets/images/magicalmoments/image24.png";
import image25 from "../assets/images/magicalmoments/image25.png";
import image26 from "../assets/images/magicalmoments/image26.png";
import image27 from "../assets/images/magicalmoments/image27.png";
import image28 from "../assets/images/magicalmoments/image28.png";
import image29 from "../assets/images/magicalmoments/image29.png";
import image30 from "../assets/images/magicalmoments/image30.png";

import video1 from "../assets/images/magicalmoments/video1.mp4";
import video2 from "../assets/images/magicalmoments/video2.mp4";
import video3 from "../assets/images/magicalmoments/video3.mp4";
import video4 from "../assets/images/magicalmoments/video4.MP4";
import video5 from "../assets/images/magicalmoments/video5.MP4";
import video6 from "../assets/images/magicalmoments/video6.MP4";
import video7 from "../assets/images/magicalmoments/video7.MP4";
import video8 from "../assets/images/magicalmoments/video8.MP4";

import happymoment1 from "../assets/images/newsevents/eventimage1.png";
import happymoment2 from "../assets/images/newsevents/eventimage2.png";
import happymoment3 from "../assets/images/new_arrival/image1.png";
import happymoment4 from "../assets/images/new_arrival/image2.png";
import happymoment5 from "../assets/images/new_arrival/image3.png";
import happymoment6 from "../assets/images/new_arrival/image4.png";
import happymoment7 from "../assets/images/connectwithus/image1.png";
import happymoment8 from "../assets/images/connectwithus/image2.png";
import happymoment9 from "../assets/images/connectwithus/image3.png";
import happymoment10 from "../assets/images/connectwithus/image4.png";

import "react-awesome-slider/dist/styles.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const MagicalMomentsWrapper = () => {
  const [width, setWidth] = useState(calculateWidth(window.innerWidth));
  var columnWidth = width;
  // const sliderRef = useRef(null); // Ref for the slider instance

  const sliderRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWidth(calculateWidth(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function calculateWidth(width) {
    if (width > 1085 && width < 1425) {
      return 230;
    } else if (width > 550 && width < 1085) {
      return 230;
    } else if (width < 550) {
      return 130;
    } else {
      return 330;
    }
  }

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const magicalMomentVideos = [
    { videos: video1 },
    { videos: video2 },
    { videos: video3 },
    { videos: video4 },
    { videos: video5 },
    { videos: video6 },
    { videos: video7 },
    { videos: video8 },
  ];

  const images2 = [
    { src: happymoment1, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: happymoment5, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: happymoment3, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: happymoment6, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: happymoment5, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: happymoment8, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: happymoment6, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: happymoment9, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: happymoment3, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: happymoment2, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: happymoment5, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: happymoment4, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image7, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: happymoment10, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: happymoment6, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image1, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: happymoment4, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: happymoment7, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image6, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: happymoment4, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image8, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image18, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image10, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: happymoment7, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image11, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image3, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image5, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image4, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image9, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image2, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image12, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image14, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image15, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image16, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image17, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image19, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image13, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image20, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image21, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image22, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image23, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image24, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image25, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image26, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image28, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image27, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image29, alt: "Rangoli Ice Cream Magical Moment Images" },
    { src: image30, alt: "Rangoli Ice Cream Magical Moment Images" },
  ];

  const clonedImages = [
    ...images2.slice(-1), // Clone the last image
    ...images2, // Original images
    ...images2.slice(0, 200), // Clone the first image
  ];

  const variants2 = {
    hidden: { opacity: 0, x: -200 }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      x: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };

  const responsive = {
    superLargeDesktop: {
      // Screens >= 3000px
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    desktop: {
      // Screens >= 1024px
      breakpoint: { max: 1024, min: 768 },
      items: 1,
    },
    tablet: {
      // Screens >= 768px
      breakpoint: { max: 768, min: 464 },
      items: 1,
    },
    mobile: {
      // Screens < 464px
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    document.title = "Rangoli Ice Cream || Magical Moments";
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center main_title_news">
        <h1 className="text-center new_arrival_title wow animate__animated animate__fadeInDown">
          Magical Moments with Rangoli
        </h1>
      </div>

      <div
        className="magical_moments_images_videos wow animate__animated animate__fadeInUp"
        style={{ marginTop: screenSize < 450 ? "1rem" : "" }}
      >
        {magicalMomentVideos.map((item, index) => (
          <VideoPlayer
            src={item.videos}
            height={
              screenSize < 450
                ? "100%"
                : screenSize < 872
                ? "240px"
                : screenSize < 1079
                ? "300px"
                : "300px"
            }
            width={
              screenSize < 450
                ? "100%"
                : screenSize < 872
                ? "300px"
                : screenSize < 1079
                ? "400px"
                : "500px"
            }
          />
        ))}
      </div>

      <div className="carousel_videos wow animate__animated animate__fadeInUp">
        <Carousel
          className="mt-5 mb-5 "
          responsive={responsive}
          // showDots={true}
          infinite={true}
        >
          {magicalMomentVideos.map((items) => {
            return (
              <div>
                <video
                  src={items.videos}
                  controls
                  style={{
                    width: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
            );
          })}
        </Carousel>
      </div>

      <div className="magical_a">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants2}
          className="magical_gallery"
        >
          <ImageGallery
            imagesInfoArray={clonedImages} // Use the duplicated array for infinite effect
            columnCount={"auto"}
            columnWidth={columnWidth}
            gapSize={18}
          />
        </motion.div>
      </div>

      <Footer />
    </>
  );
};

const MagicalMoments = () => {
  return <Header element={<MagicalMomentsWrapper />} />;
};

export { MagicalMoments };
