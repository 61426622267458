import React, { useState, useRef, useEffect } from "react";
import { toAbsoluteUrl } from "../helpers/AssetHelpers";
import { Header } from "./components/Header?ver=1.1";
import { Footer } from "./components/Footer?ver=1.1";
import { motion } from "framer-motion";
import Select from "react-select";
import { apiHeader, postData } from "../util/ApiHelper";
import { toasterrormsg, toastsuccessmsg } from "../util/reusable";
import { ToastContainer } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import backgroundImage from "../assets/images/aboutsection/advantagebackground.png";
import borderImage1 from "../assets/images/aboutsection/borderimage1.png";
import borderImage2 from "../assets/images/aboutsection/borderimage2.png";
import borderImage3 from "../assets/images/aboutsection/borderimage3.png";
import borderImage4 from "../assets/images/aboutsection/borderimage4.png";
import borderImage5 from "../assets/images/aboutsection/borderimage5.png";
import borderImage6 from "../assets/images/aboutsection/borderimage6.png";
import borderImage7 from "../assets/images/aboutsection/borderimage7.png";
import borderImage8 from "../assets/images/aboutsection/borderimage8.png";
import calanderImage from "../assets/images/careerpage/calendar.png";

const FranchiseInquiryWrapper = () => {
  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");

  const [personalInformationStateList, setPersonalInformationStateList] =
    useState([]);

  const [
    selectedPersonalInformationState,
    setSelectedPersonalInformationState,
  ] = useState("");

  const [personalInformationCityList, setPersonalInformationCityList] =
    useState([]);
  const [selectedPersonalInformationCity, setSelectedPersonalInformationCity] =
    useState("");

  const [preferredStateList, setPreferrredStateList] = useState([]);
  const [preferredState, setPreferredState] = useState("");

  const [preferredCityList, setPreferredCityList] = useState([]);
  const [preferredCity, setPreferredCity] = useState("");

  const [streetAddress, setStreetAddress] = useState("");
  const [birthDate, setBirthDate] = useState("");

  const [zipcode, setZipcode] = useState("");

  const [nationality, setNationality] = useState("");

  const [experience, setExperience] = useState("");
  const [specifyExperience, setSpecifyExperience] = useState("");

  const [ownFranchise, setOwnFranchise] = useState("");
  const [specifyOwnFranchise, setspecifyOwnFranchise] = useState("");

  const [ownPropertyOnDesiredLocation, setOwnPropertyOnDesiredLocation] =
    useState("");

  const [investmentBudget, setInvestmentBudget] = useState("");

  const [franchiseStartTime, setFranchiseStartTime] = useState("");
  const [specifyfranchiseStartTime, setSpecifyFranchiseStartTime] =
    useState("");

  const [sourceOfFunds, setSourceOfFunds] = useState("");
  const [specifySourceOfFunds, setSpecifySourceOfFunds] = useState("");

  const [whyInterestedInRangoli, setWhyInterestedInRangoli] = useState("");

  const [mediator, setMediator] = useState("");
  const [specifyMediator, setSpecifyMediator] = useState("");
  const [additionalComment, setAdditionalComment] = useState("");
  const recaptcha = useRef();

  const [error, setError] = useState([
    {
      fullName: "",
      mobileNumber: "",
      email: "",
      selectedPersonalInformationState: "",
      selectedPersonalInformationCity: "",
      streetAddress: "",
      zipcode: "",
      birthDate: "",
      nationality: "",
      preferredState: "",
      preferredCity: "",
      experience: "",
      specifyExperience: "",
      ownFranchise: "",
      specifyOwnFranchise: "",
      ownPropertyOnDesiredLocation: "",
      investmentBudget: "",
      franchiseStartTime: "",
      specifyfranchiseStartTime: "",
      sourceOfFunds: "",
      specifySourceOfFunds: "",
      whyInterestedInRangoli: "",
      mediator: "",
      specifyMediator: "",
      captcha: "",
    },
  ]);

  // remove validation function
  const removeError = (err) => {
    var tmp = [...error];
    tmp[0][err] = "";
    setError(tmp);
  };

  const validate = () => {
    var isValid = true;
    var errorTmp = [...error];
    const emailValidate = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;

    if (
      fullName.trim() == "" ||
      fullName.trim() == null ||
      fullName.trim() == undefined
    ) {
      errorTmp[0].fullName = "Please Enter Full Name";
      isValid = false;
    }
    if (
      mobileNumber.trim() == "" ||
      mobileNumber.trim() == null ||
      mobileNumber.trim() == undefined
    ) {
      errorTmp[0].mobileNumber = "Please Enter Mobile Number";
      isValid = false;
    }
    if (
      mobileNumber != "" &&
      mobileNumber != null &&
      mobileNumber != undefined
    ) {
      if (!phoneRegex.test(mobileNumber)) {
        errorTmp[0].mobileNumber = "Please Enter a Valid Mobile Number";
        isValid = false;
      }
    }
    if (email != "" && email != null && email != undefined) {
      if (!emailValidate.test(email)) {
        errorTmp[0].email = "Entered Email is Not Valid";
        isValid = false;
      }
    }
    if (
      selectedPersonalInformationState == "" ||
      selectedPersonalInformationState == null ||
      selectedPersonalInformationState == undefined
    ) {
      errorTmp[0].selectedPersonalInformationState = "Please Select a State";
      isValid = false;
    }
    if (
      selectedPersonalInformationCity == "" ||
      selectedPersonalInformationCity == null ||
      selectedPersonalInformationCity == undefined
    ) {
      errorTmp[0].selectedPersonalInformationCity = "Please Select a City";
      isValid = false;
    }
    if (zipcode == "" || zipcode == null || zipcode == undefined) {
      errorTmp[0].zipcode = "Please Enter  Zip Code";
      isValid = false;
    }
    if (
      streetAddress == "" ||
      streetAddress == null ||
      streetAddress == undefined
    ) {
      errorTmp[0].streetAddress = "Please Enter Street Address";
      isValid = false;
    }
    if (birthDate == "" || birthDate == null || birthDate == undefined) {
      errorTmp[0].birthDate = "Please Enter Your Birth Date";
      isValid = false;
    }
    if (nationality == "" || nationality == null || nationality == undefined) {
      errorTmp[0].nationality = "Please Enter Nationality";
      isValid = false;
    }
    if (nationality == "" || nationality == null || nationality == undefined) {
      errorTmp[0].nationality = "Please Enter Nationality";
      isValid = false;
    }
    if (
      preferredState == "" ||
      preferredState == null ||
      preferredState == undefined
    ) {
      errorTmp[0].preferredState = "Please Select Preferred State";
      isValid = false;
    }
    if (
      preferredCity == "" ||
      preferredCity == null ||
      preferredCity == undefined
    ) {
      errorTmp[0].preferredCity = "Please Select Preferred City";
      isValid = false;
    }
    if (experience == "" || experience == null || experience == undefined) {
      errorTmp[0].experience = "Please Select Do You Have Experience Or Not";
      isValid = false;
    }
    if (experience == "yes") {
      if (
        specifyExperience.trim() == "" ||
        specifyExperience.trim() == null ||
        specifyExperience.trim() == undefined
      ) {
        errorTmp[0].specifyExperience = "Please Specify Your Experience";
        isValid = false;
      }
    }
    if (
      ownFranchise == "" ||
      ownFranchise == null ||
      ownFranchise == undefined
    ) {
      errorTmp[0].ownFranchise =
        "Please Select Do You Currently Own any Franchise";
      isValid = false;
    }
    if (ownFranchise == "yes") {
      if (
        specifyOwnFranchise.trim() == "" ||
        specifyOwnFranchise.trim() == null ||
        specifyOwnFranchise.trim() == undefined
      ) {
        errorTmp[0].specifyOwnFranchise = "Please Specify Your Owned Franchise";
        isValid = false;
      }
    }
    if (
      ownPropertyOnDesiredLocation == "" ||
      ownPropertyOnDesiredLocation == null ||
      ownPropertyOnDesiredLocation == undefined
    ) {
      errorTmp[0].ownPropertyOnDesiredLocation =
        "Please Select Do You Currently Own Property On the Desired Location";
      isValid = false;
    }

    if (
      investmentBudget == "" ||
      investmentBudget == null ||
      investmentBudget == undefined
    ) {
      errorTmp[0].investmentBudget = "Please Enter Investment Budget";
      isValid = false;
    }
    if (investmentBudget != "" && isNaN(investmentBudget)) {
      errorTmp[0].investmentBudget = "Investment Budget is Not Valid";
      isValid = false;
    }

    if (
      franchiseStartTime == "" ||
      franchiseStartTime == null ||
      franchiseStartTime == undefined
    ) {
      errorTmp[0].franchiseStartTime = "Please Select  Franchise Start Time";
      isValid = false;
    }
    if (franchiseStartTime == "other") {
      if (
        specifyfranchiseStartTime.trim() == "" ||
        specifyfranchiseStartTime.trim() == null ||
        specifyfranchiseStartTime.trim() == undefined
      ) {
        errorTmp[0].specifyfranchiseStartTime =
          "Please Specify Your Franchise Start Time";
        isValid = false;
      }
    }
    if (
      sourceOfFunds == "" ||
      sourceOfFunds == null ||
      sourceOfFunds == undefined
    ) {
      errorTmp[0].sourceOfFunds = "Please Select Source Of Fund";
      isValid = false;
    }
    if (sourceOfFunds == "other") {
      if (
        specifySourceOfFunds.trim() == "" ||
        specifySourceOfFunds.trim() == null ||
        specifySourceOfFunds.trim() == undefined
      ) {
        errorTmp[0].specifySourceOfFunds =
          "Please Specify Your Source Of Funds";
        isValid = false;
      }
    }
    if (
      whyInterestedInRangoli == "" ||
      whyInterestedInRangoli == null ||
      whyInterestedInRangoli == undefined
    ) {
      errorTmp[0].whyInterestedInRangoli =
        "Please Enter Why Are You Interested In Rangoli";
      isValid = false;
    }

    if (mediator == "" || mediator == null || mediator == undefined) {
      errorTmp[0].mediator = "Please Select How Did You Hear About us";
      isValid = false;
    }
    if (mediator == "other") {
      if (
        specifyMediator.trim() == "" ||
        specifyMediator.trim() == null ||
        specifyMediator.trim() == undefined
      ) {
        errorTmp[0].specifyMediator = "Please Specify Your Mediator";
        isValid = false;
      }
    }

    setError(errorTmp);
    return isValid;
  };

  const addUser = async () => {
    var errorTmp = [...error];

    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      errorTmp[0].captcha = "Please Verify That You are a Human";
    } else {
      errorTmp[0].captcha = "";
    }

    if (validate()) {
      if (captchaValue) {
        var param = {
          fullName: fullName,
          email: email,
          mobileNumber: mobileNumber,
          streetAddress: streetAddress,
          cityId: selectedPersonalInformationCity,
          stateId: selectedPersonalInformationState,
          zipcode: zipcode,
          dateOfBirth: birthDate,
          nationality: nationality,
          experience: experience,
          ownFranchise: ownFranchise,
          preferredState: preferredState,
          preferredCity: preferredCity,
          ownProperyOnPreferredLocation: ownPropertyOnDesiredLocation,
          investmentBudget: investmentBudget,
          sourceOfFunds: sourceOfFunds,
          franchiseStartTime: franchiseStartTime,
          whyInterestedInRangoli: whyInterestedInRangoli,
          mediator: mediator,
        };

        if (experience == "yes") {
          param.specifyExperience = specifyExperience;
        }

        if (ownFranchise == "yes") {
          param.franchiseList = specifyOwnFranchise;
        }

        if (sourceOfFunds == "other") {
          param.otherSourceOfFunds = specifySourceOfFunds;
        }

        if (franchiseStartTime == "other") {
          param.franchiseStartTimeOther = specifyfranchiseStartTime;
        }

        if (mediator == "other") {
          param.otherMediator = specifyMediator;
        }
        if (additionalComment != "") {
          param.additionalComment = additionalComment;
        }

        var response = await postData("/adduser", param, apiHeader(false));
        if (
          String(response?.status) == "200" &&
          String(response.data.status) == "200"
        ) {
          toastsuccessmsg(response.data.message);

          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          toasterrormsg(response.data.errorMessage);
        }
      } else {
      }
    } else {
    }
  };

  const stateListApiCall = async () => {
    var response = await postData("/statelist", {}, apiHeader(false));
    if (String(response?.status) == "200" && String(response.status) == "200") {
      var data = response.data.data;
      // Transform data to have value and label both as stateName
      const transformedData = data.map((state) => ({
        value: state.stateId,
        label: state.stateName,
      }));

      setPersonalInformationStateList(transformedData);
      setPreferrredStateList(transformedData);
    } else {
      toasterrormsg(response.message);
    }
  };

  useEffect(() => {
    stateListApiCall();
  }, []);

  const cityListApiCall = async () => {
    var param = {
      stateId: selectedPersonalInformationState.value,
    };
    var response = await postData("/citylist", param, apiHeader(false));
    if (String(response?.status) == "200" && String(response.status) == "200") {
      var data = response.data.data;
      // Transform data to have value and label both as stateName
      const transformedData = data.map((city) => ({
        value: city.cityId,
        label: city.cityName,
      }));

      setPersonalInformationCityList(transformedData);
    } else {
      toasterrormsg(response.message);
    }
  };
  const cityListApiCall2 = async () => {
    var param = {
      stateId: preferredState.value,
    };
    var response = await postData("/citylist", param, apiHeader(false));
    if (String(response?.status) == "200" && String(response.status) == "200") {
      var data = response.data.data;
      // Transform data to have value and label both as stateName
      const transformedData = data.map((city) => ({
        value: city.cityId,
        label: city.cityName,
      }));

      setPreferredCityList(transformedData);
    } else {
      toasterrormsg(response.message);
    }
  };

  useEffect(() => {
    if (selectedPersonalInformationState) {
      setSelectedPersonalInformationCity("");
      cityListApiCall();
    }
  }, [selectedPersonalInformationState]);

  useEffect(() => {
    if (preferredState) {
      setPreferredCity("");
      cityListApiCall2();
    }
  }, [preferredState]);

  const interviewDateRef = useRef();
  const handleInterviewDateClick = () => {
    interviewDateRef.current.showPicker();
  };

  // Create a reference to the file input

  // Function to trigger the file input click event

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      borderRadius: "10px", // Add border-radius to the control (input area)
      color: "gray",
      fontSize: "18px",
      fontWeight: "400", // Ensure SemiBold weight is applied
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "10px", // Add border-radius to the control (input area)
      boxShadow: "none",
      backgroundColor: "#f3f3f3",
      border: "none",
      width: "100%",
      fontWeight: "400",
      fontSize: "18px",
    }),
    indicatorSeparator: () => ({
      display: "none", // Remove the vertical line next to the dropdown icon
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "gray", // Customize the color of the selected value
      fontWeight: 100,
    }),
    menu: (provided) => ({
      ...provided,
      fontWeight: "500",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#fff" : "#333", // Different color when selected
      backgroundColor: state.isSelected ? "#007bff" : "#fff",
      fontWeight: "500",
      "&:hover": {
        backgroundColor: "#0096FF", // Background on hover
        color: "#fff",
      },
    }),
  };

  const images1 = [
    {
      image: borderImage1,
      title: "15 Days Training for Franchisee",
      alt : "Training Image for Franchise"
    },
    {
      image: borderImage2,
      title: "Site Finalization Support",
      alt : "Site Finalization Support Image"
    },
    {
      image: borderImage3,
      title: "Staff Training Support",
      alt : "Staff Training Support Image"
    },
    {
      image: borderImage4,
      title: "Outlet Design & Setup",
      alt : "Outlet Design & Setup Image "
    },
  ];
  const images2 = [
    {
      image: borderImage5,
      title: "Operation Manual and Support",
      alt : "Operation manual and support image"
    },
    {
      image: borderImage6,
      title: "Equipment & Vendor Support",
      alt : "equipment & vendor support"
    },
    {
      image: borderImage7,
      title: "Marketing Through Various Path",
      alt : "Marketing  support Image"
    },
    {
      image: borderImage8,
      title: "R&D Support for Future",
      alt : "R&D Support Image"

    },
  ];

  const [width, setWidth] = useState(calculateWidth(window.innerWidth));
  var columnWidth = width;

  useEffect(() => {
    const handleResize = () => {
      setWidth(calculateWidth(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function calculateWidth(width) {
    if (width > 1085 && width < 1425) {
      return 230;
    } else if (width > 550 && width < 1085) {
      return 230;
    } else if (width < 550) {
      return 130;
    } else {
      return 330;
    }
  }

  const variants2 = {
    hidden: { opacity: 0, x: -100 }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      x: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };

  const variants4 = {
    hidden: { opacity: 0, x: 100 }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      x: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };
  const variants5 = {
    hidden: { opacity: 0, y: 100 },
    visible: (i) => ({
      opacity: 1,
      y: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };
  const animationVariants = {
    visible: { opacity: 1, scale: 1, y: 0 },
    hidden: { opacity: 0, scale: 0.5, y: 100 },
  };

  const advantages1 = {
    hidden: { opacity: 0, x: -50 }, // Start from the left
    visible: (i) => ({
      opacity: 1,
      x: 0, // Move to the center
      transition: { delay: i * 0.2, type: "spring", stiffness: 50 },
    }),
  };
  const advantages2 = {
    hidden: { opacity: 0, x: 50 }, // Start from the right
    visible: (i) => ({
      opacity: 1,
      x: 0, // Move to the center
      transition: { delay: i * 0.2, type: "spring", stiffness: 50 },
    }),
  };

    useEffect(()=>{
        document.title = 'Rangoli Ice Cream || Franchise Inquiry'; 
      },[])
      
  return (
    <>
      <ToastContainer />
      <div className="d-flex justify-content-center main_title_news wow animate__animated animate__fadeInDown">
        <h1 className="text-center new_arrival_title">
          Rangoli Ice Cream - Franchise Enquiry Form
        </h1>
      </div>

      <div className=" text-center container  wow animate__animated animate__fadeInUp" style={{ marginTop: "-1.1rem" }}>
        <p className="sub_title_form">
          Thank you for your interest in becoming a franchise partner with
          Rangoli Ice Cream! Please complete the form below, and we will get
          back to you as soon as possible.{" "}
        </p>
      </div>

      <div
        className="d-flex justify-content-center main_title_news wow animate__animated animate__fadeInDown mt-0"
      >
        <h2 className="text-center form_titles mt-0 p-1">Personal Information</h2>
      </div>

      <div className="container inquiry_form">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants4}
          className="row"
        >
          <div className="col-lg-6 col-sm-12">
            <input
              type="text"
              className="w-100 connect_form_input"
              placeholder="Full Name"
              value={fullName}
              onChange={(e) => {
                setFullName(e.target.value);
                removeError("fullName");
              }}
            />
            <div className="text-danger  fs-5 mt-1 px-3">
              {error[0].fullName}
            </div>
          </div>

          <div className="col-lg-6 col-sm-12">
            <Select
              className="connect_form_input w-100"
              styles={customStyles} // Apply custom styles
              placeholder="Select State"
              value={selectedPersonalInformationState}
              options={personalInformationStateList}
              onChange={(e) => {
                setSelectedPersonalInformationState(e);
                removeError("selectedPersonalInformationState");
              }}
            />
            <div className="text-danger  fs-5 mt-1 px-3">
              {error[0].selectedPersonalInformationState}
            </div>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants2}
          className="row"
        >
          <div className="col-lg-6 col-sm-12">
            <input
              type="text"
              className="w-100 connect_form_input"
              placeholder="Email Address"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                removeError("email");
              }}
            />
            <div className="text-danger  fs-5 mt-1 px-3">{error[0].email}</div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="connect_form_input d-flex justify-content-center">
              <Select
                className=" w-100"
                styles={customStyles} // Apply custom styles
                placeholder="Select City"
                options={personalInformationCityList}
                value={selectedPersonalInformationCity}
                onChange={(e) => {
                  setSelectedPersonalInformationCity(e);
                  removeError("selectedPersonalInformationCity");
                }}
              />
            </div>
            <div className="text-danger  fs-5 mt-1 px-3">
              {error[0].selectedPersonalInformationCity}
            </div>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants4}
          className="row"
        >
          <div className="col-lg-6 col-sm-12">
            <input
              type="text"
              className="w-100 connect_form_input"
              placeholder="Phone Number"
              value={mobileNumber}
              onChange={(e) => {
                setMobileNumber(e.target.value);
                removeError("mobileNumber");
              }}
            />
            <div className="text-danger  fs-5 mt-1 px-3">
              {error[0].mobileNumber}
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <input
              type="text"
              className="w-100 connect_form_input"
              placeholder="Street Address"
              value={streetAddress}
              onChange={(e) => {
                setStreetAddress(e.target.value);
                removeError("streetAddress");
              }}
            />
            <div className="text-danger  fs-5 mt-1 px-3">
              {error[0].streetAddress}
            </div>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants2}
          className="row"
        >
          <div className="col-lg-6 col-sm-12 ">
            <div className="connect_form_input">
              <input
                type="date"
                ref={interviewDateRef}
                onKeyDown={(e) => e.preventDefault()}
                min={new Date().toISOString().split("T")[0]} // Set the min attribute to today's date
                className="w-100 border-0"
                onClick={handleInterviewDateClick}
                style={{ backgroundColor: "#f3f3f3" }}
                value={birthDate}
                onChange={(e) => {
                  setBirthDate(e.target.value);
                  removeError("birthDate");
                }}
              />

              <div
                className="p-2"
                onClick={handleInterviewDateClick}
                style={{ cursor: "pointer" }}
              >
                <img src={calanderImage} alt="Calandar icon" />
              </div>
            </div>
            <div className="text-danger  fs-5 mt-1 px-3">
              {error[0].birthDate}
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <input
              type="text"
              className="w-100 connect_form_input"
              placeholder="Nationality"
              value={nationality}
              onChange={(e) => {
                setNationality(e.target.value);
                removeError("nationality");
              }}
            />
            <div className="text-danger  fs-5 mt-1 px-3">
              {error[0].nationality}
            </div>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants4}
          className="row"
        >
          <div className="col-lg-6 col-sm-12">
            <input
              type="text"
              className="w-100 connect_form_input"
              placeholder="Zip Code"
              value={zipcode}
              onChange={(e) => {
                setZipcode(e.target.value);
                removeError("zipcode");
              }}
            />
            <div className="text-danger  fs-5 mt-1 px-3">
              {error[0].zipcode}
            </div>
          </div>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={animationVariants}
          className="d-flex justify-content-center main_title_news mt-5"
        >
          <h2 className="text-center form_titles p-1">Business Information</h2>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants2}
          className="row mb-5"
        >
          <div className="col-lg-6 col-sm-12">
            <div className="connect_form_input d-flex justify-content-center">
              <Select
                className=" w-100"
                styles={customStyles} // Apply custom styles
                placeholder="Select State"
                options={preferredStateList}
                value={preferredState}
                onChange={(e) => {
                  setPreferredState(e);
                  removeError("preferredState");
                }}
              />
            </div>
            <div className="text-danger  fs-5 mt-1 px-3">
              {error[0].preferredState}
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="connect_form_input d-flex justify-content-center">
              <Select
                className=" w-100"
                styles={customStyles} // Apply custom styles
                placeholder="Select City"
                options={preferredCityList}
                value={preferredCity}
                onChange={(e) => {
                  setPreferredCity(e);
                  removeError("preferredCity");
                }}
              />
            </div>
            <div className="text-danger  fs-5 mt-1 px-3">
              {error[0].preferredCity}
            </div>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants4}
          className="row radio_buttons_action"
        >
          <div className="col-lg-6 col-sm-12 col-sm-12">
            <div className="w-100">
              <label htmlFor="experience">
                Do you have previous experience in running a business?
              </label>
            </div>

            <div className="radio_buttons">
              <input
                type="radio"
                name="experience"
                value={"Yes"}
                onClick={() => {
                  setExperience("yes");
                  removeError("experience");
                }}
              />

              <label htmlFor="experience">Yes</label>
              <input
                type="radio"
                name="experience"
                value={"No"}
                onClick={() => {
                  setExperience("No");
                  removeError("experience");
                }}
              />
              <label htmlFor="experience">No</label>
            </div>
            <div className="text-danger  fs-5 mt-1">{error[0].experience}</div>

            {experience == "yes" ? (
              <div>
                <textarea
                  type="text"
                  className="w-100 connect_form_textarea2 pt-5"
                  cols={6}
                  rows={4}
                  placeholder="Specify Experience"
                  onChange={(e) => {
                    setSpecifyExperience(e.target.value);
                    removeError("specifyExperience");
                  }}
                >
                  {specifyExperience}
                </textarea>
                <div className="text-danger  fs-5 mt-1 px-3">
                  {error[0].specifyExperience}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="col-lg-6 col-sm-12">
            <div className="w-100">
              <label htmlFor="ownFranchise">
                Do you currently own or operate any franchises?
              </label>
            </div>

            <div className="radio_buttons">
              <input
                type="radio"
                name="ownFranchise"
                id="ownFranchiseYes"
                value={"Yes"}
                onClick={() => {
                  setOwnFranchise("yes");
                  removeError("ownFranchise");
                }}
              />
              <label htmlFor="ownFranchise">Yes</label>

              <input
                type="radio"
                name="ownFranchise"
                id="ownFranchiseNo"
                value={"No"}
                onClick={() => {
                  setOwnFranchise("No");
                  removeError("ownFranchise");
                }}
              />
              <label htmlFor="ownFranchise">No</label>
            </div>
            <div className="text-danger  fs-5 mt-1 ">
              {error[0].ownFranchise}
            </div>

            {ownFranchise == "yes" ? (
              <div>
                <textarea
                  type="text"
                  className="w-100 connect_form_textarea2 pt-5"
                  cols={6}
                  rows={4}
                  placeholder="Franchise Details"
                  onChange={(e) => {
                    setspecifyOwnFranchise(e.target.value);
                    removeError("specifyOwnFranchise");
                  }}
                >
                  {specifyOwnFranchise}
                </textarea>
                <div className="text-danger  fs-5 mt-1 px-3">
                  {error[0].specifyOwnFranchise}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants2}
          className="row  radio_buttons_action"
        >
          <div className="col-lg-6 col-sm-12 col-sm-12">
            <div className="w-100">
              <label htmlFor="ownPropertyOnDesiredLocation">
                Do you own or lease the property for the desired location?
              </label>
            </div>

            <div className="radio_buttons">
              <input
                type="radio"
                name="ownPropertyOnDesiredLocation"
                id="ownPropertyOwn"
                value={"Own"}
                onClick={() => {
                  setOwnPropertyOnDesiredLocation("Own");
                  removeError("ownPropertyOnDesiredLocation");
                }}
              />
              <label htmlFor="ownPropertyOnDesiredLocation">Own</label>

              <input
                type="radio"
                name="ownPropertyOnDesiredLocation"
                id="ownPropertyLease"
                value={"Lease"}
                onClick={() => {
                  setOwnPropertyOnDesiredLocation("Lease");
                  removeError("ownPropertyOnDesiredLocation");
                }}
              />
              <label htmlFor="ownPropertyOnDesiredLocation">Lease</label>

              <input
                type="radio"
                name="ownPropertyOnDesiredLocation"
                id="ownPropertyNeither"
                value={"Neither"}
                onClick={() => {
                  setOwnPropertyOnDesiredLocation("Neither");
                  removeError("ownPropertyOnDesiredLocation");
                }}
              />
              <label htmlFor="ownPropertyOnDesiredLocation">Neither</label>
            </div>
            <div className="text-danger  fs-5 mt-1 ">
              {error[0].ownPropertyOnDesiredLocation}
            </div>
          </div>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={animationVariants}
          className="d-flex justify-content-center main_title_news mt-5"
        >
          <h2 className="text-center form_titles p-1">Financial Information</h2>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants4}
          className="row"
        >
          <div className="col-lg-12">
            <input
              type="text"
              className="w-100 connect_form_input"
              placeholder="Investment Budget"
              value={investmentBudget}
              onChange={(e) => {
                setInvestmentBudget(e.target.value);
                removeError("investmentBudget");
              }}
            />
            <div className="text-danger  fs-5 mt-1 px-3 ">
              {error[0].investmentBudget}
            </div>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants4}
          className="row mt-3 radio_buttons_action"
        >
          <div className="col-lg-6 col-sm-12">
            <div className="w-100">
              <label htmlFor="franchiseStartTime">
                How soon are you looking to start the franchise?
              </label>
            </div>

            <div className="radio_buttons d-flex justify-content-start flex-wrap ">
              <div className="d-flex justify-content-center align-items-center gap-2">
                <input
                  type="radio"
                  name="franchiseStartTime"
                  value={"1-3 Months"}
                  onClick={() => {
                    setFranchiseStartTime("1-3Months");
                    removeError("franchiseStartTime");
                  }}
                />
                <label htmlFor="franchiseStartTime">Within 1-3 months</label>

                <input
                  type="radio"
                  name="franchiseStartTime"
                  value={"Within 6 Months"}
                  onClick={() => {
                    setFranchiseStartTime("within6months");
                    removeError("franchiseStartTime");
                  }}
                />
                <label htmlFor="franchiseStartTime">Within 6 months</label>
              </div>

              <div className="d-flex justify-content-start align-items-center gap-2">
                <input
                  type="radio"
                  name="franchiseStartTime"
                  value={"Within A Year"}
                  onClick={() => {
                    setFranchiseStartTime("withinaYear");
                    removeError("franchiseStartTime");
                  }}
                />
                <label htmlFor="franchiseStartTime">Within a year</label>
                <input
                  type="radio"
                  name="franchiseStartTime"
                  value={"other"}
                  onClick={() => {
                    setFranchiseStartTime("other");
                    removeError("franchiseStartTime");
                  }}
                />
                <label htmlFor="franchiseStartTime">Other</label>
              </div>
            </div>
            <div className="text-danger  fs-5 mt-1 ">
              {error[0].franchiseStartTime}
            </div>

            {franchiseStartTime == "other" ? (
              <div>
                <textarea
                  type="text"
                  className="w-100 connect_form_textarea2 pt-5"
                  cols={6}
                  rows={4}
                  placeholder="Franchise Start Time"
                  onChange={(e) => {
                    setSpecifyFranchiseStartTime(e.target.value);
                    removeError("specifyfranchiseStartTime");
                  }}
                >
                  {specifyfranchiseStartTime}
                </textarea>
                <div className="text-danger  fs-5 mt-1 ">
                  {error[0].specifyfranchiseStartTime}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="w-100">
              <label htmlFor="sourceOfFunds">Source of funds ? </label>
            </div>

            <div className="radio_buttons d-flex justify-content-start flex-wrap">
              <div className="d-flex justify-content-start align-items-center gap-2">
                <input
                  type="radio"
                  name="sourceOfFunds"
                  value={"Personal Saving"}
                  onClick={() => {
                    setSourceOfFunds("personalSaving");
                    removeError("personalSaving");
                    removeError("sourceOfFunds");
                  }}
                />
                <label htmlFor="sourceOfFunds">Personal savings</label>

                <input
                  type="radio"
                  name="sourceOfFunds"
                  value={"Loans"}
                  onClick={() => {
                    setSourceOfFunds("loans");
                    removeError("sourceOfFunds");
                  }}
                />
                <label htmlFor="sourceOfFunds">Loans</label>
              </div>

              <div className="d-flex justify-content-start align-items-center gap-2">
                <input
                  type="radio"
                  name="sourceOfFunds"
                  value={"Investor Funding"}
                  onClick={() => {
                    setSourceOfFunds("investorFunding");
                    removeError("sourceOfFunds");
                  }}
                />
                <label htmlFor="sourceOfFunds">Investor Funding</label>
                <input
                  type="radio"
                  name="sourceOfFunds"
                  value={"other"}
                  onClick={() => {
                    setSourceOfFunds("other");
                    removeError("sourceOfFunds");
                  }}
                />
                <label htmlFor="sourceOfFunds">Other</label>
              </div>
            </div>
            <div className="text-danger  fs-5 mt-1 ">
              {error[0].sourceOfFunds}
            </div>
            {sourceOfFunds == "other" ? (
              <div>
                <textarea
                  type="text"
                  className="w-100 connect_form_textarea2 pt-5"
                  cols={6}
                  rows={4}
                  placeholder="Source Of Funds"
                  onChange={(e) => {
                    setSpecifySourceOfFunds(e.target.value);
                    removeError("specifySourceOfFunds");
                  }}
                >
                  {specifySourceOfFunds}
                </textarea>
                <div className="text-danger  fs-5 mt-1 ">
                  {error[0].specifySourceOfFunds}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ delay: 100 }}
          variants={variants2}
          className="d-flex justify-content-center main_title_news mt-5"
        >
          <motion.h2
            className="text-center form_titles p-1"
          >
            Other Information
          </motion.h2>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants4}
          className="row "
        >
          <div className="col-lg-6 col-sm-12">
            <textarea
              type="text"
              className="w-100 connect_form_textarea2 pt-5"
              cols={6}
              rows={4}
              placeholder="Why are you interested in a Rangoli Ice Cream franchise?"
              onChange={(e) => {
                setWhyInterestedInRangoli(e.target.value);
                removeError("whyInterestedInRangoli");
              }}
            ></textarea>
            <div className="text-danger  fs-5 mt-1 px-3 ">
              {error[0].whyInterestedInRangoli}
            </div>
          </div>

          <div className="col-lg-6 col-sm-12 mt-3 px-12 ">
            <div className="w-100">
              <label htmlFor="mediator">How did you hear about us?</label>
            </div>

            <div className="radio_buttons d-flex justify-content-start flex-wrap">
              <div className="d-flex justify-content-start align-items-center gap-2">
                <input
                  type="radio"
                  name="mediator"
                  value={"Social Media"}
                  onClick={() => {
                    setMediator("socialMedia");
                    removeError("mediator");
                  }}
                />
                <label htmlFor="mediator">Social Media</label>

                <input
                  type="radio"
                  name="mediator"
                  value={"website"}
                  onClick={() => {
                    setMediator("website");
                    removeError("mediator");
                  }}
                />
                <label htmlFor="mediator">Website</label>
              </div>

              <div className="d-flex justify-content-start align-items-center gap-2">
                <input
                  type="radio"
                  name="mediator"
                  value={"word of Mouth"}
                  onClick={() => {
                    setMediator("wordOfMouth");
                    removeError("mediator");
                  }}
                />
                <label htmlFor="mediator">Word Of Mouth</label>
                <input
                  type="radio"
                  name="mediator"
                  value={"other"}
                  onClick={() => {
                    setMediator("other");
                    removeError("mediator");
                  }}
                />
                <label htmlFor="mediator">Other</label>
              </div>
            </div>
            <div className="text-danger  fs-5 mt-1 ">{error[0].mediator}</div>
            {mediator == "other" ? (
              <div>
                <textarea
                  type="text"
                  className="w-100 connect_form_textarea2 pt-5"
                  cols={6}
                  rows={4}
                  placeholder="Please Write From Whom Did you Hear About Us?"
                  onChange={(e) => {
                    setSpecifyMediator(e.target.value);
                    removeError("specifyMediator");
                  }}
                >
                  {specifyMediator}
                </textarea>
                <div className="text-danger  fs-5 mt-1 ">
                  {error[0].specifyMediator}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants4}
          className="row mt-3"
        >
          <div className="col-lg-12">
            <textarea
              type="text"
              className="w-100 connect_form_textarea2 pt-5"
              cols={6}
              rows={12}
              onChange={(e) => {
                setAdditionalComment(e.target.value);
              }}
              placeholder="Any additional comments or questions"
            >
              {additionalComment}
            </textarea>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants4}
          className="row mt-3"
        >
          <div className="col-lg-12">
            <ReCAPTCHA
              ref={recaptcha}
              sitekey={process.env.REACT_APP_SITE_KEY}
            />
          </div>
          <div className="text-danger  fs-5 mt-1 ">{error[0].captcha}</div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants5}
          className="form_button"
        >
          <motion.button
            onClick={() => {
              addUser();
            }}
          >
            Submit
          </motion.button>
        </motion.div>
      </div>

      <div
        className="d-flex justify-content-center main_title_news"
      >
        <motion.h2
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={animationVariants}
          whileHover="hover"
          className="text-center new_arrival_title mt-5 p-2"
        >
          Advantages Of Rangoli
        </motion.h2>
      </div>
      <div className="bigimage container curv_section ">
        <div className="advantage_borders">
          <div className="border_images">
            {images1.map((items, index) => {
              return (
                <motion.div
                  custom={index}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={advantages1}
                  style={{
                    backgroundImage: `url(${backgroundImage})`,
                  }}
                  className="border_background_images best-solutions-service"
                >
                  <div className="border_content">
                    <img src={items.image} alt={items.alt} />
                  </div>
                  <div className="border_text">{items.title} </div>
                </motion.div>
              );
            })}
          </div>

          <div className="border_images2">
            {images2.map((items, index) => {
              return (
                <motion.div
                  custom={index}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={advantages2}
                  key={items.title}
                  style={{
                    backgroundImage: `url(${backgroundImage})`,
                  }}
                  className="border_background_images2 best-solutions-service"
                >
                  <div className="border_content2">
                    <img src={items.image} alt={items.alt} />
                  </div>
                  <div className="border_text">{items.title}</div>
                </motion.div>
              );
            })}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

const FranchiseInquiry = () => {
  return <Header element={<FranchiseInquiryWrapper />} />;
};

export { FranchiseInquiry };
